<template>
  <div
    class="tw-w-auto tw-bg-secondary tw-flex tw-flex-col tw-items-center tw-pt-[72px] md:tw-pt-[82px] tw-py-8 tw-px-8 sm:tw-px-12 md:tw-px-24"
  >
    <div class="tw-w-4/5">
      <h1 class="tw-text-2xl md:tw-text-4xl tw-font-bold tw-text-white tw-py-8 tw-m-0">
        {{ fnbHeadline }}
      </h1>
      <h2 class="md:tw-text-md tw-text-white tw-text-base tw-m-0 tw-pb-4 md:tw-pb-16">
        {{ fnbDescription }}
      </h2>
      <div class="tw-flex tw-flex-col tw-h-full tw-justify-center lg:tw-flex-row tw-pb-16">
        <div
          class="tw-w-full sm:tw-full tw-py-8 lg:tw-py-0 lg:tw-w-3/5 md:tw-px-16 tw-flex tw-flex-col tw-justify-center"
        >
          <!-- Render the image based on the active tab on the accordion -->
          <!-- <img :src="imagePath" :alt="activeTab" class="max-w-3/4 h-96 object-contain" /> -->
          <img :src="imagePath" :alt="activeTab" class="tw-max-h-96 tw-object-contain" />
        </div>
        <div class="tw-flex tw-flex-col lg:tw-w-2/5 tw-justify-center tw-gap-2 md:tw-gap-6">
          <SingleAccordion
            v-for="(product, index) in productList"
            :key="index"
            :product="product"
            :is-active="activeTab === product.name"
            :on-change-active-tab="onChangeActiveTab"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SingleAccordion from './productAccordion/SingleAccordion.vue';
// Keep track of the current active tab
const activeTab = ref<string>('');
const imagePath = ref<string>('');
const productList = ref<Array<any>>([]);
const fnbHeadline = ref<string>('');
const fnbDescription = ref<string>('');
import fetchImageUrl from '@/lib/fetchImageUrl';
import getSinglePageInfo from '@/lib/getSinglePageInfo';
import targetData from '@/lib/targetData';

// Change the active tab
const onChangeActiveTab = (tab: string) => {
  activeTab.value = tab;
  imagePath.value = getImageForActiveTab();
};

const getImageForActiveTab = () => {
  const product = productList.value.find((product) => product.name === activeTab.value);
  return product ? product.image : '';
};

function processPoints(data: any) {
  const points = data?.map((point: any) => point.parts[0].content);
  return points;
}

async function processScenes() {
  const pageInfo = await getSinglePageInfo('features');
  const targetHeadlineData: any = targetData(pageInfo, 'features_and_benefits_headline');
  const targetDescriptionData: any = targetData(pageInfo, 'features_and_benefits_description');
  const targetSceneData: any = targetData(pageInfo, 'scene_group');
  const processedData = await Promise.all(
    targetSceneData?.scene_group?.map(async (scene: any) => {
      const name = scene.parts.find((part: any) => part.name === 'scene_name');
      const description = scene.parts.find((part: any) => part.name === 'scene_description');
      const points = scene.parts.find((part: any) => part.name === 'scene_points');
      const image = scene.parts.find((part: any) => part.name === 'scene_image');

      return {
        name: name?.content || '',
        description: description?.content || '',
        applications: processPoints(points?.content) || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
      };
    }) || [],
  );
  productList.value = processedData;
  fnbHeadline.value = targetHeadlineData.features_and_benefits_headline;
  fnbDescription.value = targetDescriptionData.features_and_benefits_description;
  activeTab.value = processedData[0].name;
  imagePath.value = processedData[0].image;

  console.log(pageInfo);
}

// const fnbData = [{
//   name:' Unlock the Full Potential of Your Facility’s Operational Data with Four Core Scenes',
//   image_id:'',
//   image:'',
//   description: 'Explore Scenera MAIstro’s four key Scenes—Energy, Maintenance, Event, and ROI—designed to optimize every aspect of your facility management. Each Scene offers targeted insights and actionable data, empowering you to maximize efficiency, enhance safety, and boost profitability.',
//   points: [
//     'Energy Usage Trends',
//     'Occupancy',
//     'Correlational Analyses',
//     'Predictions',
//     'Anomalies'
//   ]}, {
//   name:'Unlock the Full Potential of Your Facility’s Operational Data with Four Core Scenes',
//   image_id:'',
//   image:'',
//   descrpition: 'Explore Scenera MAIstro’s four key Scenes—Energy, Maintenance, Event, and ROI—designed to optimize every aspect of your facility management. Each Scene offers targeted insights and actionable data, empowering you to maximize efficiency, enhance safety, and boost profitability.',
//   points: [
//     'Energy Usage Trends',
//     'Occupancy',
//     'Correlational Analyses',
//     'Predictions',
//     'Anomalies'
//   ],

// }]

onMounted(() => {
  processScenes();
});
</script>
<style></style>
