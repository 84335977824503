<!-- These slides are render using SwiperJS library: https://swiperjs.com/ -->
<template>
  <div v-if="dataLength" class="tw-h-full tw-w-full">
    <!-- Configure the functionalities of the Swiper Slide -->
    <swiper-container
      pagination-clickable="true"
      space-between="30"
      slides-per-view="auto"
      centered-slides="true"
      navigation="true"
      loop="true"
      autoplay-delay="15000"
      autoplay-disable-on-interaction="true"
    >
      <template v-for="(slide, index) in regularSlideData" :key="index">
        <swiper-slide class="tw-w-9/12 !tw-h-auto">
          <!-- Deduct the height of the Navigation Bar to fix it in a full screen -->
          <div
            class="tw-h-full tw-flex tw-flex-col tw-p-8 md:tw-p-24 tw-text-secondary tw-bg-lightBlue tw-justify-center"
          >
            <h1
              class="tw-font-bold tw-self-center tw-text-2xl md:tw-px-8 md:tw-text-4xl tw-pb-4 tw-m-0 md:tw-pb-10 tw-m-0"
            >
              {{ slide.headline }}
            </h1>

            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-4">
              <div class="tw-flex tw-flex-col tw-justify-center md:tw-items-center tw-basis-1/2">
                <img
                  class="tw-py-4 md:tw-p-6 tw-max-h-56 md:tw-max-h-96 tw-w-11/12 tw-object-contain tw-rounded-lg"
                  :src="slide.image"
                  alt=""
                />
              </div>
              <div
                class="tw-flex tw-flex-col tw-basis-1/2 tw-w-full lg:tw-w-1/2 md:tw-py-8 tw-justify-center md:tw-items-center"
              >
                <div class="tw-flex tw-flex-col tw-text-sm tw-gap-4 md:tw-gap-6 md:tw-text-base">
                  <p class="tw-text-secondary tw-font-bold md:tw-text-lg tw-m-0">
                    {{ slide.subheading }}
                  </p>
                  <div class="tw-flex tw-flex-col tw-gap-4 tw-text-secondary">
                    <p class="tw-m-0">
                      {{ slide.description }}
                    </p>
                    <ul class="tw-list-disc tw-list-inside tw-p-0 tw-m-0">
                      <li v-for="(point, dIndex) in slide.points" :key="dIndex">
                        {{ point }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </template>
    </swiper-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import fetchImageUrl from '@/lib/fetchImageUrl';
import getSinglePageInfo from '@/lib/getSinglePageInfo';
import targetData from '@/lib/targetData';
const regularSlideData = ref<Array<any>>([]);
const dataLength = ref<number>(0);

function processPoints(data: any) {
  const points = data?.map((point: any) => point.parts[0].content);
  return points;
}

async function processSlides() {
  const pageInfo = await getSinglePageInfo('features');
  const extractData: any = (key: string) => targetData(pageInfo, key);
  const processedData = await Promise.all(
    extractData('highlight_slide')?.highlight_slide?.map(async (slide: any) => {
      const headline = slide.parts.find((part: any) => part.name === 'highlight_slide_headline');
      const subheading = slide.parts.find(
        (part: any) => part.name === 'highlight_slide_subheading',
      );
      const description = slide.parts.find(
        (part: any) => part.name === 'highlight_slide_description',
      );
      const image = slide.parts.find((part: any) => part.name === 'highlight_slide_image');
      const points = slide.parts.find((part: any) => part.name === 'highlight_points');
      return {
        headline: headline?.content || '',
        subheading: subheading?.content || '',
        description: description?.content || '',
        image_id: image?.image_id || '',
        points: await processPoints(points.content),
        image: await fetchImageUrl(image?.image_id || ''),
      };
    }) || [],
  );
  regularSlideData.value = processedData;
  dataLength.value = processedData.length;
}

onMounted(() => {
  processSlides();
});
</script>

<style lang=""></style>
